import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface UseComponentReturn {
  showError: (error: Error) => void;
  showWarning: (message: string) => void;
  showSuccess: (message: string) => void;
}

export function useNotifications(): UseComponentReturn {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  function showError(error: Error) {
    console.log(error);
    enqueueSnackbar(t(`Errors.${error?.message || 'generic_error'}`), {
      variant: 'error',
    });
  }

  function showWarning(message: string) {
    enqueueSnackbar(message, {
      variant: 'warning',
    });
  }

  function showSuccess(message: string) {
    enqueueSnackbar(message, {
      variant: 'success',
    });
  }

  return {
    showError,
    showWarning,
    showSuccess,
  };
}
