import Amplify, { Auth } from 'aws-amplify';
interface ConfigureProps {
  amplifyConfig: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
}

export const configure = ({ amplifyConfig: { region, userPoolId, userPoolWebClientId } }: ConfigureProps): void => {
  Amplify.configure({
    Auth: {
      region,
      userPoolId,
      userPoolWebClientId,
    },
  });
};

type ParsedResponse = { status: number; body: any };

const ERROR_MESSAGE_MAP = [
  { message: 'temporary_password_expired', regex: /temporary.*password.*expired/i },
  { message: 'user_does_not_exist', regex: /user.*not.*exist/i },
  { message: 'user_already_exist', regex: /user.*already.*exist/i },
  { message: 'incorrect_password', regex: /incorrect.*username.*password/i },
  { message: 'incorrect_password_format', regex: /validation.*password/i },
  { message: 'invalid_phone_number_format', regex: /invalid.*phone/i },
  { message: 'user_cannot_confirm', regex: /user.*cannot.*confirm.*/i },
  { message: 'user_cannot_confirm', regex: /username.*cannot.*empty.*/i },
  { message: 'invalid_verfication_code', regex: /invalid.*code.*/i },
  {
    message: 'username_client_id_combination_not_found',
    regex: /username.*client.*combination.*not.*/i,
  },
  { message: 'user_already_exist', regex: /.*account.*already.*exist.*/i },
  {
    message: 'not_yet_confirmed_reset_password',
    regex: /.*reset.*password.*no.*verified.*email/i,
  },
  { message: 'not_yet_confirmed', regex: /.*user.*not.*confirmed/i },
  { message: 'request_limit_exceeded', regex: /.*limit.*exceeded/i },
  { message: 'request_limit_exceeded', regex: /.*quota.*exceeded/i },
  { message: 'request_limit_exceeded', regex: /.*attempts.*exceeded/i },
  { message: 'generic_error', regex: /.*amazon.*ses.*sandbox/i },
  { message: 'user_not_found_or_federated_external', regex: /.*user.*not.*found.*federated/i },
  { message: 'user_disabled', regex: /.*user.*disabled/i },
];

function parseError(error: Error) {
  const match = ERROR_MESSAGE_MAP.filter((entry) => entry.regex.test(error.message));
  if (match && match.length === 0) {
    throw error;
  }

  throw Error(match[0].message);
}

function parseBody(response: ParsedResponse) {
  if (response.body?.errors?.[0]) {
    return parseError(Error(response.body?.errors?.[0] || 'generic_error'));
  }

  if (response.status === 400) {
    return parseError(Error('generic_error'));
  }

  return response.body;
}

function parseResponse(response: Response): any {
  if (response.status == 401) {
    throw Error('unauthorized');
  }
  if (!response.ok && response.status !== 400) {
    throw Error('generic_error');
  } else {
    return response.json().then((body) => ({ status: response.status, body }));
  }
}

function parseCsrf(response: ParsedResponse) {
  const { csrf, ...rest } = response.body || response;
  csrf && localStorage.setItem('CSRF', csrf);
  return rest;
}

export interface AuthData {
  state: string;
  code_challenge: string;
}
interface InitSignInProps {
  email: string;
  password: string;
}

type SignInProps = AuthData & InitSignInProps;

const signIn = (data: SignInProps): Promise<any> =>
  fetch('/auth-signin', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(parseResponse)
    .then(parseBody)
    .then(parseCsrf);

interface ChangePasswordProps extends SignInProps {
  challengeName: 'changePassword';
  newPassword: string;
  confirmNewPassword: string;
}

const changePassword = (data: ChangePasswordProps): Promise<any> =>
  fetch('/auth-signin', {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(parseResponse)
    .then(parseBody);

const callVerifyEndpoint = (data: {
  email?: string;
  requestNewPassword?: boolean;
  newPassword?: string;
  token?: string;
  code?: string;
}): Promise<any> =>
  fetch('/auth-verify', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(parseResponse)
    .then(parseBody);

type EmailValidatorResponse = {
  data: {
    EmailValidator: {
      isValid: boolean;
      didYouMean: string;
    };
  };
};
const validateEmail = (email: string): Promise<EmailValidatorResponse['data']['EmailValidator']> =>
  fetch('https://order.mineko.io/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `query EmailValidator($email: String!) {
        EmailValidator(email: $email) {
          isValid
          didYouMean
        }
      }`,
      variables: { email },
    }),
  })
    .then(parseResponse)
    .then(parseBody)
    .then(({ data }: EmailValidatorResponse) => data.EmailValidator);

const initUrl = new URL('/auth-signin', window.location.origin);

const init = (requestedUri = ''): Promise<AuthData> => {
  initUrl.searchParams.set('requestedUri', requestedUri);
  return fetch(initUrl.toString(), {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(parseResponse)
    .then(parseCsrf);
};

const run =
  (method: string) =>
  (...props: any) =>
    (Auth as any)[method](...props).catch(parseError);

export default {
  forgotPassword: (data: { email: string }) => callVerifyEndpoint({ ...data, requestNewPassword: true }),
  forgotPasswordSubmit: (data: { newPassword?: string; token?: string; code?: string }) => callVerifyEndpoint(data),
  signUp: run('signUp'),
  changePassword,
  signIn,
  init,
  verifyEmail: callVerifyEndpoint,
  validateEmail,
};
