import { ApolloLink, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { RetryFunctionOptions } from '@apollo/client/link/retry/retryFunction';

export const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);

  if (operation.variables && !operation.variables.file) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }

  return forward(operation);
});

type ErrorType =
  | {
      type: 'UNKNOWN_ERROR';
      message: string;
      payload?: never;
    }
  | {
      type: 'AUTH_EXPIRED';
      message: string;
      payload: {
        redirectUrl: string;
      };
    };

export type OnErrorType = (error: ErrorType) => void;

export const errorLink = (handleError?: OnErrorType) =>
  onError(({ networkError, graphQLErrors }) => {
    graphQLErrors && console.error('API Error: graphQLErrors', JSON.stringify(graphQLErrors));
    networkError && console.error('API Error: networkError', JSON.stringify(networkError));
    if (!handleError) return;
    if (networkError) {
      const error = networkError as ServerError;
      if (error?.statusCode === 401) {
        handleError({
          type: 'AUTH_EXPIRED',
          message: error.message,
          payload: {
            redirectUrl: error.result.error.redirectUrl,
          },
        });
        return;
      }
      handleError({ type: 'UNKNOWN_ERROR', message: error.message });
    }
    if (graphQLErrors) {
      handleError({ type: 'UNKNOWN_ERROR', message: graphQLErrors[0].message });
    }
  });

// by default we only want to retry network errors
export const retryLink = (retryCondition?: RetryFunctionOptions['retryIf']) =>
  new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => (retryCondition ? retryCondition(error, _operation) : !!error.networkError),
    },
  });
