import React, { useContext } from 'react';

/**
 * @description The feature tracking context is used to make the app name available throughout the app without the need of redeclaring it.
 * Provide the app name you want to track behaviour for by passing it to the Provide Value.
 */
export const FeatureTrackingContext = React.createContext<string | null>(null);

export const useTrackFeature = () => {
  const appName = useContext(FeatureTrackingContext);

  const handleTracking = (featureId: string, eventType: string, payload: unknown, entityId?: string) => {
    if (!appName)
      throw new Error(
        'App name is missing, have you wrapped this component inside the FeatureTrackingContext.Provider?',
      );

    try {
      fetch(`/capture-event`, {
        method: 'POST',
        body: JSON.stringify({
          featureId,
          appName,
          timestamp: new Date().toISOString(),
          eventType,
          payload,
          ...(entityId && { entityId }),
        }),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return { track: handleTracking };
};
