import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

type Integration = 'Replay';

interface InitProps {
  dsn: string;
  release: string;
  environment?: string;
  integrations?: Integration[];
}

export const SentrySetContext = (name: string, context: Record<string, string>) => {
  Sentry.setContext(name, context);
};
export const SentryInit = ({ dsn, release, environment, integrations = [] }: InitProps): void => {
  const applyIntegrations: any[] = [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error'],
    }),
  ];

  integrations?.includes('Replay') &&
    applyIntegrations.push(new Sentry.Replay({ maskAllText: true, blockAllMedia: true }));

  return Sentry.init({
    dsn,
    release,
    environment,
    // since v6 of sentry there is an issue withg sessionTracking, so this needs to be disabled until they fix it
    // see https://github.com/getsentry/sentry-javascript/issues/3234
    autoSessionTracking: false,
    integrations: applyIntegrations,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const SentryErrorBoundary = Sentry.ErrorBoundary;
