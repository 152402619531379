import { ApolloClient, ApolloLink, InMemoryCache, concat, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { RetryFunctionOptions } from '@apollo/client/link/retry/retryFunction';
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link';
import { get as getCookie } from 'js-cookie';
import { retryLink } from './links';

const baseAuthOptions: AuthOptions = {
  type: AUTH_TYPE.NONE,
};

const authMiddleware = (csrfCookieName?: string) =>
  new ApolloLink((operation, forward) => {
    csrfCookieName &&
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'x-csrf': getCookie(csrfCookieName),
        },
      }));

    return forward(operation);
  });

export type Endpoint = {
  url: string;
  clientName: string;
};

const getEndpointLinks = (endpoints: Endpoint[]) => {
  let link: ApolloLink | null = null;
  endpoints.forEach((endpoint) => {
    if (!endpoint.clientName) {
      throw new Error('clientName is required for all endpoints');
    }

    if (!endpoint.url) {
      throw new Error('url is required for all endpoints');
    }

    if (!link) {
      link = new HttpLink({ uri: endpoint.url });
    }

    const newLink = new HttpLink({ uri: endpoint.url });

    link = ApolloLink.split((operation) => operation.getContext().clientName === endpoint.clientName, newLink, link);
  });

  if (!link) {
    throw new Error("No link was created for the client. Make sure you're passing in endpoints");
  }
  return link;
};

type ClientPropsType = {
  endpoints: Endpoint[];
  cache: InMemoryCache;
  authOptions?: AuthOptions;
  region?: string;
  links?: ApolloLink[];
  retryCondition?: RetryFunctionOptions['retryIf'];
  csrfCookieName?: string;
};

/**
 *
 *
 * @param {Endpoint[]} endpoints - array of endpoints to use for the client. First endpoint is used for the auth link
 * @param {InMemoryCache} cache - cache to use for the client
 * @param {AuthOptions} authOptions - advanced auth options to pass to auth link
 * @param {string} [region='AWS_REGION'] - region to use for the client
 * @param {ApolloLink[]} [links=[]] - array of links to use for the client
 * @param {RetryFunctionOptions['retryIf']} [retryCondition] - override function for setting the retry coniditions of graphl requests
 * @param {string} [csrfCookieName] - name of the csrf cookie to use for the client
 * @returns
 */
export const initializeClient = ({
  endpoints,
  cache,
  authOptions = {} as AuthOptions,
  region = 'AWS_REGION',
  links = [],
  retryCondition,
  csrfCookieName,
}: ClientPropsType): ApolloClient<NormalizedCacheObject> => {
  // use the primary endpoint as the default for auth link
  const firstEndpointUrl = endpoints[0].url;
  const config = {
    url: firstEndpointUrl,
    region,
    auth: { ...baseAuthOptions, ...authOptions },
  };

  const link = concat(
    authMiddleware(csrfCookieName),
    ApolloLink.from([createAuthLink(config), ...links, retryLink(retryCondition), getEndpointLinks(endpoints)]),
  );

  return new ApolloClient({
    link,
    cache,
  });
};
