{
  "Global": {
    "submit": "Weiter",
    "email": "E-Mail",
    "code": "PIN",
    "new_password": "Neues Passwort",
    "set_password": "Passwort festlegen",
    "first_name": "Vorname",
    "last_name": "Name",
    "male": "Herr",
    "female": "Frau",
    "register": "registrieren",
    "login": "einloggen",
    "login_page": "Login",
    "register_page": "Registrieren",
    "or": "oder",
    "already_registered": "Bereits registriert?",
    "please_wait": "Einen Moment bitte"
  },
  "Federate": {
    "withGoogle": "weiter mit Google"
  },
  "Verify": {
    "title": "Bestätigung Ihrer E-Mailadresse",
    "description": "Bitte bestätigen Sie ihre E-Mailadresse, um alle Funktionen nutzen zu können. Wir senden Ihnen eine E-Mail mit einem Link zur Verifizierung.",
    "success": "Sie erhalten in Kürze eine E-Mail mit einem Link zur Verifizierung."
  },
  "Validation": {
    "email": {
      "required": "Bitte geben Sie ihre E-Mailadresse ein.",
      "format": "Ungültiges Format."
    },
    "code": {
      "required": "Bitte geben sie den PIN ein.",
      "format": "Es müssen 6 Zahlen sein."
    },
    "password": {
      "required": "Bitte geben Sie ein Passwort ein.",
      "format": "8 Zeichen aus Groß- und Kleinbuchstaben, sowie Zahlen.",
      "format_strong": "8 Zeichen aus Groß- und Kleinbuchstaben, sowie Zahlen und Sonderzeichen. Folgende Sonderzeichen sind erlaubt: = + - ^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ `"
    },
    "confirm_password": {
      "required": "Bitte wiederholen Sie das Passwort.",
      "format": "Die eingegebenen Passwörter stimmen nicht überein."
    },
    "first_name": {
      "required": "Bitte geben Sie einen Vorname ein"
    },
    "last_name": {
      "required": "Bitte geben Sie einen Namen ein"
    },
    "salutation": {
      "required": "Bitte wählen Sie eine Anrede"
    },
    "accept": {
      "required": "Bitte akzeptieren Sie unsere AGB und Datenschutzbestimmungen"
    }
  },
  "Login": {
    "title": "Jetzt einloggen",
    "description": "Loggen Sie sich ein mit Ihrer E-Mail und dem von Ihnen definierten Passwort.",
    "forgot_password": "Passwort vergessen?"
  },
  "Register": {
    "title": "Jetzt kostenlos registrieren",
    "description": "Mit Ihrer Registrierung stehen Ihnen zahlreiche Funktionen rund um ihre Nebenkosten zur Verfügung. Dafür müssen  Sie ein kostenloses Konto einrichten.",
    "terms": "Ich akzeptiere die <0>AGB</0> und <1>Datenschutzbestimmungen</1> der Mineko GmbH.",
    "success": "Ihre Registrierung war erfolgreich, Sie werden gleich weitergeleitet.",
    "email_invalid": "Diese E-Mailadresse ist ungültig. Bitte überprüfen Sie Ihre Eingabe.",
    "email_did_you_mean": "Meinten Sie vielleicht {{email}}?",
    "email_validation_in_progress": "Bitte warten Sie einen Moment, während wir Ihre E-Mail-Adresse überprüfen."
  },
  "ChangePassword": {
    "title": "Passwort ändern",
    "description": "Setzten Sie Ihr neues Passwort fest",
    "forgot_password": "Passwort vergessen?"
  },
  "ForgotPassword": {
    "request_new_password": {
      "title": "Bitte E-Mail eintragen",
      "description": "Wir senden Ihnen per E-Mail einen Link zum Zurücksetzen Ihres Passworts zu.",
      "password_request_success": "Sie erhalten in Kürze eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts."
    },
    "set_new_password": {
      "title": "Neues Passwort definieren",
      "description": "Bitte legen Sie hier Ihr neues Passwort fest.",
      "password_change_success": "Ihr Passwort wurde erfolgreich gespeichert, bitte loggen Sie sich mit Ihrem neuen Passwort ein.",
      "no_email_received": "E-Mail nicht bekommen ?"
    }
  },
  "Errors": {
    "user_does_not_exist": "Diese E-Mailadresse ist noch nicht registriert",
    "user_already_exist": "Diese E-Mailadresse ist bereits registriert",
    "incorrect_password": "Passwort oder E-Mailadresse falsch",
    "incorrect_password_format": "Bitte wählen Sie ein anderes Passwort",
    "invalid_phone_number_format": "Telefonnumer ist falsch",
    "user_cannot_confirm": "E-Mailadresse konnte nicht bestätigt werden. Bitte versuchen Sie es erneut oder kontaktieren Sie uns.",
    "invalid_verfication_code": "Code / TAN ist bereits abgelaufen. Bitte fragen Sie einen neuen an.",
    "username_client_id_combination_not_found": "Leider ist ein Fehler aufgetreten. Bitte überprüfen Sie ihre Eingabe.",
    "not_yet_confirmed": "Bitte bestätigen Sie zuerst ihre E-Mailadresse. Dazu haben wir Ihnen eine E-Mail zugesandt.",
    "not_yet_confirmed_reset_password": "Sie müssen zuerst ihre E-Mailadresse bestätigen, um ihr Passwort zurückzusetzen. Dazu haben wir Ihnen eine E-Mail zugesandt.",
    "request_limit_exceeded": "Bitte versuchen Sie es in einigen Minuten erneut.",
    "force_forgot_password": "Bitte setzen Sie Ihr Passwort zurück.",
    "generic_error": "Leider ist etwas schief gelaufen. Bitte probiere Sie es erneut oder kontaktieren Sie uns.",
    "unauthorized": "Ihr Link ist abgelaufen oder ungültig. Bitte fordern Sie einen neuen an.",
    "temporary_password_expired": "Ihr initiales Passwort ist abgelaufen. Bitte nutzen sie die 'Passwort vergessen?' Funktion, um ein neues Passwort zu setzen.",
    "user_not_found_or_federated_external": "Passwort zurücksetzen nicht möglich: Bitte überprüfen Sie Ihre E-Mail-Adresse oder nutzen Sie 'weiter mit Google' um sich anzumelden.",
    "user_disabled": "Passwort oder E-Mailadresse falsch"
  }
}
