import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import isRetryAllowed from 'is-retry-allowed';

export const retryCondition = (error: AxiosError) => error.isAxiosError && isRetryAllowed(error);

export const Axios = {
  setRetry: (retries?: number) =>
    axiosRetry(axios, {
      retryDelay: axiosRetry.exponentialDelay,
      retries: retries ?? 8,
      retryCondition,
    }),
};
